// src/components/DealerForm.js

import React, { useState } from 'react';
import { BASE_URL } from '../../api/config'; 
import { FaUser, FaPhone, FaMapMarkerAlt, FaCode ,FaIdCard ,FaBriefcase } from 'react-icons/fa'; // Importing icons
import Swal from 'sweetalert2'; 
import "./DealerForm.css";

const DealerForm = ({ onClose, onSubmitSuccess  }) => {

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    profProof: null,
    usr_name: '',
    usr_num: '',
    address: '',
    pincode: '',
    LiceNum: '',
    experience: '',
    status: 'individual',
    idProof: null, 
    lisenProof: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleprofileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      profProof: e.target.files[0], // Store the file object
    }));
  };
  const handleaadharChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      idProof: e.target.files[0], // Store the file object
    }));
  };
  const handleLicenceChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      lisenProof: e.target.files[0], // Store the file object
    }));
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(formData.usr_name)) {
      Swal.fire({
        title: 'Invalid Name',
        text: 'Name must contain only letters and spaces.',
        icon: 'error',
        showConfirmButton: true,
      });
      setLoading(false);
      return; // Stop the form submission if the name is invalid
    }
  
    // Phone number validation
    const phoneRegex = /^[6-9]\d{9}$/;

    if (!phoneRegex.test(formData.usr_num)) {
      Swal.fire({
        title: 'Invalid Phone Number',
        text: 'Phone number must be 10 digits and start with 6, 7, 8, or 9.',
        icon: 'error',
        showConfirmButton: true,
      });
      setLoading(false);
      return; // Stop the form submission if the phone number is invalid
    }
  
    // Check if the phone number contains repeating digits
    const isRepeating = formData.usr_num.split('').every(digit => digit === formData.usr_num[0]);
    if (isRepeating) {
      Swal.fire({
        title: 'Invalid Phone Number',
        text: 'Phone number should not have repeated digits.',
        icon: 'error',
        showConfirmButton: true,
      });
      setLoading(false);
      return;
    }
  

    const licenceNumRegex = /^[A-Z]{2}\d{13}$/; 
    if (!formData.LiceNum || !licenceNumRegex.test(formData.LiceNum)) {
      let errorMessage = '';
    
      if (!formData.LiceNum || formData.LiceNum.trim() === '') {
        errorMessage = 'Enter Licence Number';
      } else if (!/^[A-Z]{2}/.test(formData.LiceNum)) {
        errorMessage = 'Licence number should start with a valid state code (e.g., TN, KA)';
      } else if (!/^\d{13}$/.test(formData.LiceNum.substring(2))) {
        errorMessage = 'Licence Number should contain exactly 13 digits after the state code';
      }
    
      Swal.fire({
        title: 'Invalid Licence Number',
        text: errorMessage,
        icon: 'error',
        showConfirmButton: true,
      });
      setLoading(false);
      return; // Stop submission if any condition fails
    }
    
    const experienceRegex = /^[0-9]{1,2}$/;
    if (!experienceRegex.test(formData.experience)) {
      Swal.fire({
        title: 'Invalid Experience',
        text: 'Experience should be a numeric value (1-99 years).',
        icon: 'error',
        showConfirmButton: true,
      });
      setLoading(false);
      return; // Stop the form submission if the experience is invalid
    }

    const pincodeRegex = /^[0-9]{6}$/;

    if (!pincodeRegex.test(formData.pincode)) {
      Swal.fire({
        title: 'Invalid Pincode',
        text: 'Pincode must be exactly 6 numeric digits.',
        icon: 'error',
        showConfirmButton: true,
      });
      setLoading(false);
      return; // Stop the form submission if the pincode is invalid
    }
  
    const accessToken = sessionStorage.getItem("accessToken");
    
    const staffAccess = JSON.parse(sessionStorage.getItem("staffaccess") || "{}");
    const whoislog = sessionStorage.getItem("whoislog");
    const formDataToSubmit = new FormData();
  
    if (formData.profProof) {
      formDataToSubmit.append('prof_pic', formData.profProof); // Append the profile proof file
    }
    if (formData.lisenProof) {
      formDataToSubmit.append('Licence_pic', formData.lisenProof); // Append the profile proof file
    }
  
    // Append all form data
    formDataToSubmit.append('who_is', 'Dealer'); // Adjust 'who_is' based on your logic
    formDataToSubmit.append('usr_name', formData.usr_name);
    formDataToSubmit.append('usr_num', formData.usr_num);
    formDataToSubmit.append('address', formData.address);
    formDataToSubmit.append('pincode', formData.pincode);
    formDataToSubmit.append('licn_num', formData.LiceNum);
    formDataToSubmit.append('exp_yr', formData.experience);
    formDataToSubmit.append('del_sts', formData.status);

    formDataToSubmit.append('staffrole',whoislog);
    formDataToSubmit.append('staffId',staffAccess.staff_logid);


  
    if (formData.idProof) {
      formDataToSubmit.append('id_prf', formData.idProof); // Append the ID proof file
    }
  
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 300000);
  
    try {
      const response = await fetch(`${BASE_URL}/api/RegisterDealerWeb`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataToSubmit,
        signal: controller.signal,
      });
      clearTimeout(timeoutId);
  
      if (response.status === 400) {
        const result = await response.json();
        Swal.fire({
          title: result.msg,
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,  // Hides the OK button
          customClass: {
            popup: 'custom-popup',
            title: 'custom-title',
            content: 'custom-content'
          }
        });
      }
  
      if (response.status === 200) {
        Swal.fire({
          title: 'Dealer added successfully!',
          html: '<p class="custom-subtitle">The dealer will appear in the Dealer list once their OTP is verified.</p>',
          icon: 'success',
          showConfirmButton: true,
          customClass: {
            popup: 'custom-popup',
            title: 'custom-title',
            content: 'custom-content'
          }
        }).then(() => {
          onSubmitSuccess();
        });
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request timed out');
      }
      console.error('Error:', error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="dealer-form-popup">
      <h2>Add Dealer</h2>
      <form onSubmit={handleSubmit}>

  
<div className='dealerForm'>
  
<div className='firstdiv'>
<div>
          <label>
            <FaUser /> {/* Dealer Name Icon */}
            <input
              type="text"
              name="usr_name"
              placeholder="Dealer Name"
              value={formData.usr_name}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            <FaPhone /> {/* Phone Number Icon */}
            <input
              type="text"
              name="usr_num"
              placeholder="Phone Number"
              value={formData.usr_num}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            <FaMapMarkerAlt /> {/* Address Icon */}
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </label>
        </div>
       
</div>
        
       <div className='seconddiv'>
       <div>
          <label>
            <FaCode /> {/* Pincode Icon */}
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={formData.pincode}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            <FaIdCard  /> {/* Pincode Icon */}
            <input
              type="text"
              name="LiceNum"
              placeholder="Licence Number"
              value={formData.LiceNum}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            <FaBriefcase  /> {/* Pincode Icon */}
            <input
              type="text"
              name="experience"
              placeholder="Driving Experience"
              value={formData.experience}
              onChange={handleChange}
              required
            />
          </label>
        </div>
       </div>
     
       </div>
       <div>
          <label>
            <strong>Dealer Status :</strong>
          </label>
          <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',border:'1px solid lightgrey',marginBottom:'10px',borderRadius:'5px',paddingTop:'15px'}}>
            <label>
              <input
                type="radio"
                name="status"
                value="individual"
                checked={formData.status === 'individual'}
                onChange={handleChange}
              />
              Individual
            </label>
            <label>
              <input
                type="radio"
                name="status"
                value="travels"
                checked={formData.status === 'travels'}
                onChange={handleChange}
              />
              Travels
            </label>
          </div>
        </div>

        <div>
          <label>
            <strong>Aadhaar Proof * </strong>
            <input
              type="file"
              name="idProof"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" // Acceptable file types
              onChange={handleaadharChange}
              required
            />
          </label>
        </div>

        <div>
          <label>
            <strong>Driver Licence * </strong>
            <input
              type="file"
              name="idProof"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" // Acceptable file types
              onChange={handleLicenceChange}
              required
            />
          </label>
        </div>

        <div>
          <label>
            <strong>Dealer profile * </strong>
            <input
              type="file"
              name="idProof"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" // Acceptable file types
              onChange={handleprofileChange}
              required
            />
          </label>
        </div>
      


        <div className='dealer-form-foot'>
        {loading ?
              <p className='loadingCircularspinner'></p>
            : <button type="submit">Register Dealer</button>
          }

            <button type="button" onClick={onClose}>Cancel</button>
        </div>

      </form>
    </div>
  );
};

export default DealerForm;
