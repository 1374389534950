import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import "../assets/css/header.css";
import main_logo from "../assets/logo/logo.png";

const Header = () => {
    const [hideTopNav, setHideTopNav] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeSection, setActiveSection] = useState("HOME");
    const navigate = useNavigate();
    const location = useLocation(); // Track the current route

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const sea_taxi = () => {
        navigate("/Home");
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 70,
                behavior: 'smooth',
            });
        }
    };

    // Hide and show the top navigation bar based on scroll direction
    useEffect(() => {
        let lastScrollY = window.scrollY;
        let timeoutId; // To debounce the scroll event for better performance

        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // Hide the nav only when scrolling down past a certain threshold
            if (currentScrollY > 80 && currentScrollY > lastScrollY) {
                setHideTopNav(true);
            } else if (currentScrollY < lastScrollY || currentScrollY <= 80) {
                setHideTopNav(false);
            }

            lastScrollY = currentScrollY;

            // Clear timeout to avoid unnecessary re-renders
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                lastScrollY = window.scrollY; // Reset to the latest scroll position after debounce
            }, 100);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            clearTimeout(timeoutId);
        };
    }, []);

    // Update active link based on scroll position or pathname
    useEffect(() => {
        if (location.pathname.startsWith("/Home")) {
            const sections = [
                { id: "home", name: "HOME" },
                { id: "how-it-works", name: "HOW IT WORK" },
                { id: "why-choose-us", name: "WHY CHOOSE US" },
                { id: "download-app", name: "DOWNLOAD APP" },
            ];
    
            const handleScroll = () => {
                const scrollPosition = window.scrollY;
    
                for (let section of sections) {
                    const element = document.getElementById(section.id);
                    if (element) {
                        const offsetTop = element.offsetTop - 80; // Adjust for header height
                        const offsetBottom = offsetTop + element.offsetHeight;
    
                        if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
                            setActiveSection(section.name);
                            return;
                        }
                    }
                }
    
                if (scrollPosition < 100) {
                    setActiveSection("HOME");
                }
            };
    
            const debounceScroll = () => {
                clearTimeout(window.scrollDebounceTimer);
                window.scrollDebounceTimer = setTimeout(handleScroll, 50);
            };
    
            window.addEventListener("scroll", debounceScroll);
    
            return () => {
                window.removeEventListener("scroll", debounceScroll);
            };
        } else {
            const pathToSectionMap = {
                "/About": "ABOUT",
                "/Contact": "CONTACT",
            };
            setActiveSection(pathToSectionMap[location.pathname] || "HOME");
        }
    }, [location.pathname]);
    



    const handleLoginClick = () => {
        navigate('/login');
      };



    return (
        <>
        {/* Top Navigation Bar */}
      <div className={`top-nav ${hideTopNav ? "hide" : ""}`}>
        <div className="top-bar-content">
          <span className="top-bar-email"><img
src="https://cdn-icons-png.flaticon.com/128/15047/15047587.png"             
alt="email signin" 
            className="topNavIcon-Img" 
            /> seataxi@gmail.com</span>

          <span className="top-bar-contact"><img
src="https://cdn-icons-png.flaticon.com/128/16794/16794935.png"             
alt="phone signin" 
            className="topNavIcon-Img" 
            /> +91 98941 45031, +04651-275555</span>

            
          <span className="top-bar-time"><img
src="https://cdn-icons-png.flaticon.com/128/16742/16742889.png"             
alt="Time signin" 
            className="topNavIcon-Img" 
            />24/7 Service</span>
        </div>
      </div>


            <header className={`web-taxi-header ${hideTopNav ? "top" : ""}`}>
                <div className="web-taxi-logo">
                    <img src={main_logo} alt="Logo" />
                </div>
                <nav className={menuOpen ? 'web-taxi-menu open' : 'web-taxi-menu'}>
                    <ul>
                        <li>
                            <NavLink
                                to="/Home"
                                className={activeSection === "HOME" ? 'active-link' : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    sea_taxi();
                                    setMenuOpen(false);
                                }}
                            >
                                HOME
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/About"
                                className={activeSection === "ABOUT" ? 'active-link' : ''}
                                onClick={() => setActiveSection("ABOUT")}
                            >
                                ABOUT
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/Contact"
                                className={activeSection === "CONTACT" ? 'active-link' : ''}
                                onClick={() => setActiveSection("CONTACT")}
                            >
                                CONTACT
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/Home"
                                className={activeSection === "HOW IT WORK" ? 'active-link' : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/Home");
                                    setTimeout(() => {
                                        scrollToSection('how-it-works');
                                    }, 0);
                                    setMenuOpen(false);
                                }}
                            >
                                HOW IT WORK
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/Home"
                                className={activeSection === "WHY CHOOSE US" ? 'active-link' : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/Home");
                                    setTimeout(() => {
                                        scrollToSection('why-choose-us');
                                    }, 0);
                                    setMenuOpen(false);
                                }}
                            >
                                WHY CHOOSE US
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/Home"
                                className={activeSection === "DOWNLOAD APP" ? 'active-link' : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/Home");
                                    setTimeout(() => {
                                        scrollToSection('download-app');
                                    }, 0);
                                    setMenuOpen(false);
                                }}
                            >
                                DOWNLOAD APP
                            </NavLink>
                        </li>
                    </ul>
                </nav>

                <div className="nav-actions">
                    <button className="nav-login-btn" onClick={handleLoginClick}>Login</button>
                </div>
                <div className="web-taxi-menu-icon" onClick={toggleMenu}>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </div>
            </header>
        </>
    );
};

export default React.memo(Header);
