import React, { useState, useEffect } from "react";
import "../styles/profile.css";
import { BASE_URL } from '../api/config';
import Swal from 'sweetalert2'; 
import ConfirmationModal from "../components/Model/ConfirmationModal"; // Import your modal component
import ImagePopup from '../components/reuseable/ImagePopup';
import profileImg from "../assets/images/profile-02.png";

const Profile = () => {
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  // State to handle active tab (My Details or Contact Us)
  const [activeTab, setActiveTab] = useState("myDetails");
  const [updatenum, Updatenumber] = useState("");
  const [selectedContactId, setSelectedContactId] = useState(null);

  // State to store user details from sessionStorage
  const [userDetails, setUserDetails] = useState(null);

  // State to store contact details fetched from the backend
  const [contactDetails, setContactDetails] = useState([]);

  // State to store new contact form inputs
  const [newContact, setNewContact] = useState({
    email: "",
    phn: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState("");

  const [AddContactAccess, setAddContactAccess] = useState(false);


  // Fetch user details from sessionStorage when the component mounts
  useEffect(() => {
    const staffAccess = JSON.parse(sessionStorage.getItem("staffaccess") || "{}");
    const whoislog = sessionStorage.getItem("whoislog");
    const accessCategory = staffAccess.access_category
    ? JSON.parse(staffAccess.access_category)
    : {};
   
    if ((staffAccess && accessCategory?.["Add Access"]?.Contact) || whoislog === 'Admin') {
      setAddContactAccess(true);
    } else {
      setAddContactAccess(false);
    }
    const storedUserDetails = sessionStorage.getItem("userDetails");
    if (storedUserDetails) {
      setUserDetails(JSON.parse(storedUserDetails));
    }
  }, []);

  // Handle tab switching between "My Details" and "Contact us"
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "contactUs") {
      fetchContactDetails();
    }
  };

  // Fetch contact details from backend API when "Contact us" is clicked
  const fetchContactDetails = async () => {
    setLoading(true);
    const accessToken = sessionStorage.getItem("accessToken");
    try {
      const response = await fetch(`${BASE_URL}/api/contactus`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch contact details');
      }

      const data = await response.json();
      setContactDetails(data);
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }finally {
      setLoading(false);
    }
  };


  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContact({ ...newContact, [name]: value });
  };

// Basic email validation function
const validateEmail = (email) => {
  // Updated email regex
  const emailRegex = /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};


  const validatePhoneNumber = (phone) => {
    // Check if phone number starts with 6,7,8, or 9 and is not the same digit repeated 10 times
    const phoneRegex = /^[6-9]\d{9}$/;
    const isRepeatedDigit = /^(.)\1{9}$/.test(phone);
    return phoneRegex.test(phone) && !isRepeatedDigit;
  };

  // Handle form submission to add new contact
  const handleAddContact = async (e) => {
    e.preventDefault();

    // Validate email and phone number
    if (!validateEmail(newContact.email)) {
      alert("Please enter a valid email.");
      return;
    }

    if (!validatePhoneNumber(newContact.phn)) {
      alert("Please enter a valid phone number.");
      return;
    }

    const accessToken = sessionStorage.getItem("accessToken");
    const staffAccess = JSON.parse(sessionStorage.getItem("staffaccess") || "{}");
    const whoislog = sessionStorage.getItem("whoislog");
    try {
      const response = await fetch(`${BASE_URL}/api/contactus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          email : newContact.email,
          phone : newContact.phn,
          staffrole : whoislog,
          staffId : staffAccess.staff_logid??'0',
      }),
      });

      if (response.status === 409) {
        const data = await response.json(); // Parse the JSON response
        Swal.fire({
          title: data.message, 
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
          customClass: {
            popup: 'custom-popup',
            title: 'custom-title',
            content: 'custom-content'
          }
        });
        setNewContact({
          email: "",
          phn: "",
        });
        return;
      }
      if (response.status === 403) {
        const data = await response.json(); // Parse the JSON response
        Swal.fire({
          title: data.message, 
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
          customClass: {
            popup: 'custom-popup',
            title: 'custom-title',
            content: 'custom-content'
          }
        });
        setNewContact({
          email: "",
          phn: "",
        });
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to add contact');
      }

      Swal.fire({
        title: 'New Contact Added Successfully',
        // text: 'Invalid OTP',
        icon: 'success',
        timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      }).then(() => {
        fetchContactDetails();
        setNewContact({ email: "", phn: "" }); 
      });

    } catch (error) {
      console.error('Error adding contact:', error);
    }
  };

  const openModal = (dealerId) => {
    setSelectedContactId(dealerId);
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    handleDeleteContact(selectedContactId);
    setModalOpen(false);
  };

  const handleDeleteContact = async (contactId) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const staffAccess = JSON.parse(sessionStorage.getItem("staffaccess") || "{}");
    const whoislog = sessionStorage.getItem("whoislog");
    try {
      const response = await fetch(`${BASE_URL}/api/contactus/${contactId}/${whoislog}/${staffAccess.staff_logid}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete contact');
      }
  
      // Refresh the list after deletion
      fetchContactDetails();
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };



  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (file && allowedTypes.includes(file.type)) {
      if (file.size > 5 * 1024 * 1024) {
        setError("File size should be less than 5MB.");
      } else {
        setError("");
        setSelectedImage(file); 
      }
    } else {
      setError("Please select a valid image (jpg, jpeg, png).");
    }
  };


  const handleAddProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Check if an image is selected
    console.log('updatenum',updatenum,'userDetails.staff_num',userDetails.staff_num);
 // Validate phone number change
 if (updatenum) {
  if (!validatePhoneNumber(updatenum)) {
    alert("Please enter a valid phone number.");
    setLoading(false);
    return;
  }
 }

 // Confirm phone number change if updated
 if (updatenum && updatenum !== userDetails.staff_num) {
   const result = await Swal.fire({
     title: "Are you sure?",
     text: "Are you sure you want to change the phone number?",
     icon: "warning",
     showCancelButton: true,
     confirmButtonText: "Yes, change it!",
     cancelButtonText: "No, cancel",
   });

   if (!result.isConfirmed) {
     setLoading(false);
     return;
   }
 }


    if (!selectedImage) {
      setError("Please choose an image before submitting.");
      setLoading(false);
      return;
    }
  
    const accessToken = sessionStorage.getItem("accessToken");
    // Prepare form data to send to the backend
    const formData = new FormData();
    formData.append("profile_pic", selectedImage); // Image data
    formData.append("id", userDetails.staff_id);  
    formData.append("usid", userDetails.staff_userId);  

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 300000);
    try {
      // Make API call to the backend with the profile image and user number
      const response = await fetch(`${BASE_URL}/api/Update_Staff_Profile`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`, 
        },
        signal: controller.signal,
      });
      clearTimeout(timeoutId);
      // Check if the response is in JSON format
      const contentType = response.headers.get('Content-Type');
      
      let responseData;
      if (contentType && contentType.includes('application/json')) {
        // Parse as JSON if the content type is JSON
        responseData = await response.json();
      } else {
        // Parse as text if it's not JSON
        responseData = await response.text();
      }
  
      if (response.ok) {
        console.log(responseData, 'image');
  
        Swal.fire({
          title: 'Profile changed',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
          customClass: {
            popup: 'custom-popup',    // Class for the alert popup
            title: 'custom-title',    // Class for the title
            content: 'custom-content' // Class for the content text
          }
        }).then(async () => {
          setSelectedImage(null); 
          document.getElementById("file-input").value = ""; // Reset file input
  
          await setUserDetails(responseData.userDetails); 
          await sessionStorage.setItem('userDetails', JSON.stringify(responseData.userDetails));
          await window.location.reload();
        });
      } else {
        console.log('Error:', responseData);
        setError(responseData.message || "Error updating profile.");
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request timed out');
      }
      console.log('error', error);
      setError("Something went wrong. Please try again.");
    }finally{
      setLoading(false);
    }
  };
  
  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');
  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };

  return (
    <div className="settings">
      <div className="settings__wrapper">
        <h2 className="settings__title">Admin Profile</h2>

        <div className="settings__top">
          <button
            className={`setting__btn ${activeTab === "myDetails" ? "active__btn" : ""}`}
            onClick={() => handleTabClick("myDetails")}
          >
            My Details
          </button>
        {AddContactAccess && (
          <button
            className={`setting__btn ${activeTab === "contactUs" ? "active__btn" : ""}`}
            onClick={() => handleTabClick("contactUs")}
          >
            Contact us
          </button>
          )}  
        </div>

        {activeTab === "myDetails" && userDetails && (
          <div className="details__form">
            <h2 className="profile__title">Profile</h2>
            <p className="profile__desc">
              Update your photo and personal details here
            </p>
            
            <form onSubmit={handleAddProfile}>
              <img
                src={
                 ( userDetails.staff_profile && userDetails.staff_profile != null)
                  ? `${BASE_URL}/${userDetails.staff_profile}`
                : profileImg}
                alt={`${userDetails.staff_name} pic`}
                className="Admin__image"
                onClick={() => userDetails.staff_profile && openImagePopup(`${BASE_URL}/${userDetails.staff_profile}`)}
              />

              <div className="form__group">

              <div>
                  <label>Role</label>
                  <input
                    type="text"
                    value={userDetails.staff_role || ""}
                    placeholder="Staff Role"
                    readOnly
                  />
                </div>

                <div>
                  <label>User Name</label>
                  <input
                    type="text"
                    value={userDetails.staff_name || ""}
                    placeholder="Staff Name"
                    readOnly
                  />
                </div>
                <div>
                  <label>User ID</label>
                  <input
                    type="text"
                    value={userDetails.staff_userId || ""}
                    placeholder="Staff ID"
                    readOnly
                  />
                </div>

                <div>
                  <label>Phone Number</label>
                  <input
                type="text"
                // value={updatenum || userDetails.staff_num || ""}
                value={userDetails.staff_num}
                placeholder="+880 17*******"
                onChange={(e) => Updatenumber(e.target.value)} 
                readOnly
              />
                </div>
              </div>

              <div className="form__group">
                <div>
                  <label>Your Photo</label>
                  <p className="profile-img__desc">
                    This will be displayed in your profile
                  </p>
                  <input type="file"  id="file-input" accept="image/*" onChange={handleImageChange} />
                    {error && <p className="error-message">{error}</p>}
                 
                </div>
               
              </div>
              
              {loading 
              ? <p className='loadingCircularspinner'></p>
              : <button type="submit" className="add__contact-btn">
                Save Changes
              </button>}

            </form>
          </div>
        )}

        {activeTab === "contactUs" && (
          <div className="contact__form">
            <h2 className="profile__title">Contact Details</h2>
            <p className="profile__desc">Update your Seataxi ContactUs Details</p>

            {/* Form to add new contact */}
            <form onSubmit={handleAddContact}>
              <div className="form__group">
                <div>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={newContact.email}
                    onChange={handleInputChange}
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div>
                  <label>Phone Number</label>
                  <input
                    type="text"
                    name="phn"
                    value={newContact.phn}
                    onChange={handleInputChange}
                    placeholder="Enter phone number"
                    required
                  />
                </div>
              </div>
              <button type="submit" className="add__contact-btn">
                Add Contact
              </button>
            </form>

            {/* List of existing contacts */}
            {loading 
              ?<center><div className="waitingspinner2"></div></center>
              :contactDetails.length > 0 ? (
              <div className="contactus-list">
                {contactDetails.map((contact, index) => (
                  <div className="contactus" key={index}>
                     
                    <i className="ri-delete-bin-fill" onClick={() => 
                      openModal(contact.id)}></i>
                      {/* onClick={() => handleDeleteContact(contact.id)} */}
                    <p>{contact.email}</p>
                    <p>{contact.phn}</p>
                   
                  </div>
                 
                ))}
              </div>
            ) : (
              <p className="error-message">No contact details available.</p>
            )}
          </div>
        )}
      </div>
      <ConfirmationModal 
        isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
        onConfirm={handleConfirm} 
        message={`Are you sure you want to delete this contact ?`} 
      />


        {imagePopupOpen && (
            <ImagePopup 
              src={popupImageSrc} 
              alt="Vehicle" 
              onClose={closeImagePopup} 
            />
          )}

    </div>
  );
};




export default Profile;
