import React, { useState, useEffect } from "react";
import { BASE_URL } from "../api/config";
import { ResponsiveContainer, BarChart, Bar, XAxis, Tooltip, Legend } from "recharts";

const MileCharts = () => {
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState(null);

  const processBookingData = (data) => {
    const monthYearData = {};

    data.forEach(({ dateTime, verify }) => {
      const date = new Date(dateTime);
      const year = date.getFullYear();
      const month = date.toLocaleString("default", { month: "short" });
      const key = `${month}-${year}`;

      if (!monthYearData[key]) {
        monthYearData[key] = {
          name: key,
          pending: 0,
          accepted: 0,
          cancelled: 0,
        };
      }

      const status = parseInt(verify, 10);
      if (status === 0) {
        monthYearData[key].pending += 1;
      } else if (status === 200) {
        monthYearData[key].accepted += 1;
      } else if (status === 404) {
        monthYearData[key].cancelled += 1;
      } else {
        console.warn(`Unhandled verify status: ${status}`);
      }
    });

    return Object.values(monthYearData).sort((a, b) =>
      new Date(`01 ${a.name.split("-")[0]} ${a.name.split("-")[1]}`) -
      new Date(`01 ${b.name.split("-")[0]} ${b.name.split("-")[1]}`)
    );
  };

  useEffect(() => {
    const fetchBookingStatics = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}/api/fetchBookingStatics`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const processedData = processBookingData(data);
        setChartData(processedData);
      } catch (error) {
        setError(error);
      }
    };

    fetchBookingStatics();
  }, []);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (chartData.length === 0) {
    return <p>Loading or no data available...</p>;
  }

  return (
    <div className="chart__wrapper">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData}>
        <XAxis
          dataKey="name"
          stroke="#000" // Dark black for the axis line
          tick={{ fill: "#000", fontSize: 12, fontWeight: "bold" }} // Dark black for tick labels
        />

          <Tooltip wrapperClassName="tooltip__style" cursor={false} />
          <Legend />
          <Bar dataKey="pending" fill="#ffc107" barSize={30} name="Pending" />
          <Bar dataKey="accepted" fill="#28a745" barSize={30} name="Accepted" />
          <Bar dataKey="cancelled" fill="#dc3545" barSize={30} name="Cancelled" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MileCharts;
