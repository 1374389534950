import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../api/config'; 
import "../../styles/dealerdtls.css";
import ImagePopup from '../../components/reuseable/ImagePopup';
import StaffForm from '../../components/Form/staff/AddStaff';
import defaultProfileImage from '../../assets/images/profile-02.png';
import ConfirmationModal from "../../components/Model/ConfirmationModal";
import Swal from 'sweetalert2'; 



const StaffDetails = () => {
  const [staff, setStaffDtl] = useState([]);
  const [StaffFilter, setStaffFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [AddstaffFormOpen, setAddstaffFormOpen] = useState(false);

  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');

 const [isModalOpen, setModalOpen] = useState(false);

 const staffAccess = JSON.parse(sessionStorage.getItem("staffaccess") || "{}");
 const whoislog = sessionStorage.getItem("whoislog");

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [itemsPerPage, setItemsPerPage] = useState(15);
  
    const openImagePopup = (src) => {
        setPopupImageSrc(src);
        setImagePopupOpen(true);
      };
    
      const closeImagePopup = () => {
        setImagePopupOpen(false);
      };

 const [selectedStaff, setSelectedStaff] = useState(null);

      const openModal = (DelId) => {
        setSelectedStaff(DelId);
        setModalOpen(true);
      };

      
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);  
    setCurrentPage(1);
    };

  // Fetch dealer requests from backend
  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const accessToken = sessionStorage.getItem("accessToken");
        const response = await fetch(`${BASE_URL}/api/getAllSeataxiStaff`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`, 
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch staff');
        }

        const data = await response.json();
        setStaffDtl(data)
        setStaffFilter(data); // Set fetched data to the state
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStaff();
  }, []);


  useEffect(() => {
    if (searchTerm) {
      const filteredData = staff.filter((contact) => {
        const name = contact.staff_name?.toLowerCase() || ''; // Handle undefined name
        const phone = String(contact.staff_num || ''); // Convert phone to string and handle undefined/null
        return (
          name.includes(searchTerm.toLowerCase()) ||
          phone.includes(searchTerm)
        );
      });
      setStaffFilter(filteredData);
    } else {
      setStaffFilter(staff); // Reset to full list if no search term
    }
  }, [staff, searchTerm]);
  


  const handleAdminStaffDelete = async (imgid,filename) => {
    const accessToken = sessionStorage.getItem("accessToken");
  
    try {
      const response = await fetch(`${BASE_URL}/api/deleteSetaxiStaff`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          staffId: selectedStaff.staff_id, 
          staffUsId: selectedStaff.staff_userId,

          staffrole : whoislog,
          staffid : staffAccess.staff_id??'0',
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete Staff');
      }

      await Swal.fire({
        title: 'Staff deleted successfully!',
        // text: 'Invalid OTP',
        icon: 'success',
        timer: 2000,
            showConfirmButton: false, 
            customClass: {
              popup: 'custom-popup',   
              title: 'custom-title',  
              content: 'custom-content'
            }
      }).then(() => {
        window.location.reload();
      });;
       
    } catch (error) {
      console.error('Error deleting type:', error);
    }finally{
      setModalOpen(false);
    }
  };

  const categories = [
    {
      name: "Client",
      actions: ["View","ActiveDeactivate"],
    },
    {
      name: "Dealer",
      actions: ["Add","View","ActiveDeactivate"],
    },
    {
      name: "Vehicle",
      actions: ["View","ActiveDeactivate"],
    },
   
    {
      name: "Pending Request",
      actions: ["Dealer", "Vehicle", "Update"],
    },
    {
      name: "Add Access",
      actions: ["Vehicle_TypeCategory", "Contact"],
    },
    {
      name: "Bookings",
      actions: ["View"],
    },
    {
      name: "Customer Contactus",
      actions: ["View"],
    },
  ];

  const actionDisplayNames = {
    ActiveDeactivate: "Activate And Deactivate",
    Add: "Add",
    Dealer: "Dealer",
    Vehicle: "Vehicle",
    Update: "Update",
    Vehicle_TypeCategory: "Vehicle Type/Category",
    Contact: "Contact",
  };


  const [accessModalVisible, setAccessModalVisible] = useState(false);
  const [selectedAccessStaff, setSelectedAccessStaff] = useState(null);
  const [updatedAccess, setUpdatedAccess] = useState({});


  const handleCheckboxChange = (categoryName, actionName) => {
    setUpdatedAccess((prevAccess) => ({
      ...prevAccess,
      [categoryName]: {
        ...prevAccess[categoryName], // Spread existing actions under the category
        [actionName]: !prevAccess[categoryName]?.[actionName], // Toggle the specific action
      },
    }));
  };

  const handleGlobalCheckAll = (isChecked) => {
    const updatedCategories = categories.reduce((acc, category) => {
      const allActions = category.actions.reduce((actionsAcc, action) => {
        actionsAcc[action] = isChecked;
        return actionsAcc;
      }, {});
      acc[category.name] = allActions;
      return acc;
    }, {});
  
    setUpdatedAccess(updatedCategories);
  };
  
  // Check if all categories are fully selected
  const isGlobalCheckAll = categories.every((category) =>
    category.actions.every((action) => updatedAccess[category.name]?.[action])
  );



  const handleAccessUpdate = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    console.log('updatedAccess',updatedAccess);
    try {
      const response = await fetch(`${BASE_URL}/api/update-Staff-access`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          accessid:selectedAccessStaff.access_id,
          staffId: selectedAccessStaff.staff_logid,
          updatedAccess,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Access updated successfully:', data);
         Swal.fire({
                title: 'Success!',
                text: 'Access updated successfully',
                icon: 'success',
                timer: 2000,
            }).then(() => {
                window.location.reload();
            });
      } else {
        console.error('Failed to update access:', response.statusText);
        Swal.fire({
            title: 'Error!',
            text: response.data.message || 'Invalid credentials.',
            icon: 'error',
            timer: 2000,
        });
      }
    } catch (error) {
      console.error('Error while updating access:', error);
      alert('An error occurred while updating access.');
    } finally {
      setAccessModalVisible(false); // Close the modal
    }
  };
  



  const indexOfLastClient = currentPage * itemsPerPage;
  const indexOfFirstClient = indexOfLastClient - itemsPerPage;
  const currentStaff = StaffFilter.slice(indexOfFirstClient, indexOfLastClient);
  const totalPages = Math.ceil(StaffFilter.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // if (loading) return <center><div className="waitingspinner"></div></center>;
  // if (error) return <p>Error: {error}</p>;

  return (

        <div className="dashboard">
          <div className="dashboard__wrapper">
          <div className="dealerdtls-requests">

 
  <div className='topflex'>
  <h2 className="dealerdtls-requests__heading">User Details</h2>
    <div className="search__box">
              <input
                type="text"
                placeholder="Search by name or phone number"
                value={searchTerm}  // Controlled input
                onChange={handleSearchChange}  // Update search term on input
              />
          <span>
            <i class="ri-search-line"></i>
          </span>
        </div>

        <button className="add-dealer-button" 
        onClick={() => setAddstaffFormOpen(true)}
        >
                <span className="button-text">Add User</span>
                <span className="button-icon">
                    <i className="ri-add-line"></i>
                </span>
            </button>

    </div>
  <table className="dealerdtls-requests__table">
    <thead className="dealerdtls-requests__thead">
      <tr className="dealerdtls-requests__thead-row">
        <th className="dealerdtls-requests__thead-cell">ID</th>
        <th className="dealerdtls-requests__thead-cell">Profile</th>
        <th className="dealerdtls-requests__thead-cell">Name</th>
        <th className="dealerdtls-requests__thead-cell">Mobile Number</th>
        <th className="dealerdtls-requests__thead-cell">Role</th>
        <th className="dealerdtls-requests__thead-cell">User ID</th>
        {/* <th className="dealerdtls-requests__thead-cell">Password</th> */}
        <th className="dealerdtls-requests__thead-cell">Access</th>
        <th className="dealerdtls-requests__thead-cell">Action</th>
      </tr>
    </thead>
    <tbody className="dealerdtls-requests__tbody">
  {currentStaff.map((staffs, index) => {
    // Parse the access_category JSON string into an object

    return (
      <tr key={staffs.id} className="dealerdtls-requests__tbody-row">
        <td className="dealerdtls-requests__tbody-cell">
          {indexOfFirstClient + index + 1}
        </td>
        <td className="dealerdtls-requests__tbody-cell">
          <img
            src={staffs.staff_profile && staffs.staff_profile != null &&staffs.staff_profile.trim() !== "" ? `${BASE_URL}/${staffs.staff_profile}` : require('../../assets/images/profile-02.png')}
            alt={`staff Profile`}
            className="dealerdtls-requests__profile-image"
            onClick={() => staffs.staff_profile ? openImagePopup(`${BASE_URL}/${staffs.staff_profile}`) : null}
          />
        </td>
        <td className="dealerdtls-requests__tbody-cell">{staffs.staff_name}</td>
        <td className="dealerdtls-requests__tbody-cell">{staffs.staff_num}</td>
        <td className="dealerdtls-requests__tbody-cell">{staffs.staff_role}</td>
        <td className="dealerdtls-requests__tbody-cell">{staffs.staff_userId}</td>
        {/* <td className="dealerdtls-requests__tbody-cell">{staffs.staff_password}</td> */}
        <td className="dealerdtls-requests__tbody-cell">
        <button className="view-access" onClick={() => {
                    setSelectedAccessStaff(staffs);
                    setAccessModalVisible(true);
                    setUpdatedAccess(JSON.parse(staffs.access_category) || {});
                  }}>
                    View Access
                  </button>
        </td>
        <td className="dealerdtls-requests__tbody-cell">
        
          <button className='staffdelete' onClick={() => openModal(staffs)}>
            Delete
          </button>
        </td>
      </tr>
    );
  })}
</tbody>

</table>

{/* Access popup */}
{accessModalVisible && (
  <>
    <div className="access-modal-overlay" onClick={() => setAccessModalVisible(false)}></div>
    <div className="access-modal">
      <h3>{selectedAccessStaff.staff_name}'s Access</h3>
      <div>
        <label>
          <input
            type="checkbox"
            onChange={(e) => handleGlobalCheckAll(e.target.checked)}
            checked={isGlobalCheckAll}
          />
          Check All
        </label>
      </div>
      {categories.map((category, idx) => (
        <div key={idx}>
          <h4>{category.name}</h4>
          {category.actions.map((action) => (
            <div key={action}>
              <label>
                <input
                  type="checkbox"
                  checked={updatedAccess[category.name]?.[action] || false}
                  onChange={() => handleCheckboxChange(category.name, action)}
                />
                 {actionDisplayNames[action] || action}
              </label>
            </div>
          ))}
        </div>
      ))}
      <button onClick={handleAccessUpdate}>Update Access</button>
      <button onClick={() => setAccessModalVisible(false)}>Close</button>
    </div>
  </>
)}




  {/* Pagination Controls */}
  {StaffFilter.length > 10 && (  
  <div className="pagination">
            <button 
              onClick={() => handlePageChange(currentPage - 1)} 
              disabled={currentPage === 1}
              className="pagination__button"
            >
              &laquo; Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`pagination__button ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button 
              onClick={() => handlePageChange(currentPage + 1)} 
              disabled={currentPage === totalPages}
              className="pagination__button"
            >
              Next &raquo;
            </button>
          </div>
  )}

<ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleAdminStaffDelete}
                message={`Are you sure you want to Delete this User ?`}
              />


{imagePopupOpen && (
            <ImagePopup 
              src={popupImageSrc} 
              alt="User" 
              onClose={closeImagePopup} 
            />
          )}

{loading?<center><div className="waitingspinner2"></div></center>
:(StaffFilter.length < 1 && (
   <center className='dlrno--data'><p>No Users found...!</p></center>
))}


</div>



{AddstaffFormOpen && (
  <div className="dealer-form-overlay">
    <StaffForm 
       onSubmitSuccess={() => {
        setAddstaffFormOpen(false); 
        window.location.reload();
      }}
      onClose={() => {
        setAddstaffFormOpen(false); 
      }} 
   
    />
  </div>
)}

          </div>
          
        </div>
 
  );
};

export default StaffDetails;

