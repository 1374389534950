import React, {useState} from 'react';
import "../assets/css/contact.css";
import cont_logo from "../assets/image/bg1.png";
import Footer from './Footer';
import Header from './Header';
import axios from 'axios';
import { BASE_URL } from '../api/config'; 
import Swal from 'sweetalert2'; 

const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    
      // Validation error state
      const [errors, setErrors] = useState({});
    
      // Handle input changes
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    

      const validate = () => {
        const newErrors = {};

        if (!formData.name.trim()) {
          newErrors.name = 'Name is required';
        } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
          newErrors.name = 'Name must contain only letters (A-Z or a-z)';
        }
      
        // Email validation: Proper email format
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
          newErrors.email = 'Enter a valid email address';
        }

        if (!formData.phone.trim()) {
          newErrors.phone = 'Phone number is required';
        } else if (!/^[6-9]\d{9}$/.test(formData.phone)) {
          newErrors.phone = 'Phone number must start with 6, 7, 8, or 9 and be 10 digits';
        } else if (/^(\d)\1{9}$/.test(formData.phone)) {
          newErrors.phone = 'Phone number cannot have all digits the same';
        }

        if (!formData.message.trim()) {
          newErrors.message = 'Message is required';
        }
      
        return newErrors;
      };
      
    

      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('formData',formData);
      
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
          try {
            const response = await fetch(`${BASE_URL}/api/Contactwebsite`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
            });
      
            if (!response.ok) {
                Swal.fire({
                    title: response.message, 
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false,
                    customClass: {
                      popup: 'custom-popup',
                      title: 'custom-title',
                      content: 'custom-content'
                    }
                  });
            }
      
            const responseData = await response.json();
            console.log(responseData);
            Swal.fire({
                title: 'Details Added Successfully',
                icon: 'success',
                timer: 2000,
                    showConfirmButton: false,
                    customClass: {
                      popup: 'custom-popup',
                      title: 'custom-title',
                      content: 'custom-content'
                    }
              }).then(() => {
              setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
              }); 
            });
           
            setErrors({});
          } catch (error) {
            console.error('Error submitting the form:', error);
          }
        } else {
          setErrors(validationErrors);
        }
      };
      
    


  return (
   <>
   <Header/>
   <section id="CONTACT">
   <div className='sea_taxi_contact_page'>
      <div className='sea_taxi_page'>
        <div className='sea_taxi_contact_page_1'>
          <div className='sea_taxi_contact_pic_add'>
            <img src={cont_logo} alt='Contact Logo' />
          </div>
          <form onSubmit={handleSubmit} className='sea_taxi_conts_input'>
            <h1>Contact Us</h1>

            <h2>Name</h2>
            <input
              type='text'
              name='name'
              placeholder='Enter your Name'
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <p className='erroralert'>{errors.name}</p>}

            <h2>Email</h2>
            <input
              type='text'
              name='email'
              placeholder='Enter your Email'
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className='erroralert'>{errors.email}</p>}

            <h2>Phone</h2>
            <input
              type='text'
              name='phone'
              placeholder='Enter your Phone Number'
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <p className='erroralert'>{errors.phone}</p>}

            <h2>Leave us a few words</h2>
            <textarea
              name='message'
              placeholder='Type here ...'
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && <p className='erroralert'>{errors.message}</p>}

            <div className='contct_bts'>
              <button type='submit'>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
   </section>
   <Footer/>
   </>
  )
}

export default React.memo(Contact);