import { useEffect } from 'react';
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import AOS from 'aos';

var overscrollOptions = {
  enable: true,
  effect: 'bounce',
  damping: 0.15,
  maxOverscroll: 150,
};

var options = {
  damping: 0.07,
  plugins: {
    overscroll: { ...overscrollOptions },
  },
};

const Scroll = () => {
  useEffect(() => {
    // Initialize Smooth Scrollbar
    Scrollbar.use(OverscrollPlugin);
    const scrollbar = Scrollbar.init(document.body, options);

    // Refresh AOS on scroll update
    scrollbar.addListener(() => {
      AOS.refresh();
    });

    return () => {
      if (Scrollbar) Scrollbar.destroy(document.body);
    };
  }, []);

  return null;
};

export default Scroll;
