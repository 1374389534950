import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../api/config';
import "../../styles/dealerdtls.css";
import "./HistoryDetails.css";
import Swal from 'sweetalert2'; 


const HistoryDetails = () => {

  const [WorkingHistory, setWorkingHistory] = useState([]);
  const [HistoryFilter, setHistoryFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterMonth, setFilterMonth] = useState('');
  const [filterYear, setFilterYear] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchClientRequests = async () => {
      try {
        const accessToken = sessionStorage.getItem("accessToken");
        const response = await fetch(`${BASE_URL}/api/getWorking_History_Details`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch Client requests');
        }

        const data = await response.json();
        setWorkingHistory(data);
        setHistoryFilter(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClientRequests();
  }, []);

  useEffect(() => {
    let filteredData = WorkingHistory;

    if (searchTerm) {
      filteredData = filteredData.filter((history) => {
        const staff_role = history.staff_role?.toLowerCase() || '';
        const category = String(history.category || '');
        return (
          staff_role.includes(searchTerm.toLowerCase()) ||
          category.includes(searchTerm)
        );
      });
    }

    if (filterDate) {
        filteredData = filteredData.filter(history => {
          // Format the datetime to 'yyyy-MM-dd' for comparison
          const historyDate = new Date(history.datetime).toISOString().split('T')[0];
          return historyDate === filterDate; // Compare with filterDate (assumed in 'yyyy-MM-dd')
        });
      }
      
      if (filterMonth) {
        filteredData = filteredData.filter(history => {
          const historyMonth = new Date(history.datetime).getUTCMonth() + 1; // Months are 0-based
          return String(historyMonth) === String(filterMonth); // Compare as strings for consistency
        });
      }
      
      if (filterYear) {
        filteredData = filteredData.filter(history => {
          const historyYear = new Date(history.datetime).getUTCFullYear();
          return String(historyYear) === String(filterYear); // Compare as strings for consistency
        });
      }
      

    if (selectedAction) {
        filteredData = filteredData.filter(history => history.action === selectedAction);
      }
  
      if (selectedCategory) {
        filteredData = filteredData.filter(history => history.category === selectedCategory);
      }

      setHistoryFilter(filteredData);
      setCurrentPage(1); 
  }, [WorkingHistory, searchTerm, filterDate, filterMonth, filterYear, selectedAction, selectedCategory]);

  const indexOfLastClient = currentPage * itemsPerPage;
  const indexOfFirstClient = indexOfLastClient - itemsPerPage;
  const currentClient = HistoryFilter.slice(indexOfFirstClient, indexOfLastClient);
  const totalPages = Math.ceil(HistoryFilter.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const [selectedItems, setSelectedItems] = useState([])
 // Handle individual checkbox
 const handleCheckboxChange = (id) => {
  setSelectedItems((prevSelected) =>
    prevSelected.includes(id)
      ? prevSelected.filter((itemId) => itemId !== id)
      : [...prevSelected, id]
  );
};

// Handle "Check All" checkbox
const handleCheckAll = () => {
  if (selectedItems.length === currentClient.length) {
    setSelectedItems([]);
  } else {
    setSelectedItems(currentClient.map((item) => item.id));
  }
};

// Handle delete selected items
const handleDelete = async () => {
  const accessToken = sessionStorage.getItem("accessToken");
console.log('selectedItems',selectedItems);
  try {
    // Send a DELETE request to the backend
    const response = await fetch(`${BASE_URL}/api/deleteHistory`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ ids: selectedItems }),
    });

    if (!response.ok) {
      throw new Error('Failed to delete history');
    }
    Swal.fire({
                    title: 'Success!',
                    text: 'History Deleted Successfully',
                    icon: 'success',
                    timer: 2000,
                })
    // Filter out the deleted items from the frontend
    const updatedHistory = WorkingHistory.filter(
      (item) => !selectedItems.includes(item.id)
    );

    setWorkingHistory(updatedHistory);
    setHistoryFilter(updatedHistory);
    setSelectedItems([]);
  } catch (error) {
    console.error('Error deleting history:', error);
    alert('An error occurred while deleting the items.');
  }
};


const [popupData, setPopupData] = useState(null);
const [isPopupVisible, setPopupVisible] = useState(false);

const handleIconClick = (data) => {
  setPopupData(data); // Set the data for the popup
  setPopupVisible(true); // Show the popup
};

const closePopup = () => {
  setPopupData(null); // Clear the popup data
  setPopupVisible(false); // Hide the popup
};

  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="dealerdtls-requests">
          <div className="topflex">
            <h2 className="dealerdtls-requests__heading">Seataxi Working History</h2>
            <div className="search__box">
              <input
                type="text"
                placeholder="Search by name or phone number"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span>
                <i className="ri-search-line"></i>
              </span>
            </div>
          </div>

          {/* Filter Inputs */}
          <div className="history-filter-controls">
            <input
              type="date"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
              placeholder="Filter by Date"
            />
            <select value={filterMonth} onChange={(e) => setFilterMonth(e.target.value)}>
              <option value="">Select Month</option>
              {[...Array(12).keys()].map(month => (
                <option key={month + 1} value={month + 1}>{month + 1}</option>
              ))}
            </select>
            <select value={filterYear} onChange={(e) => setFilterYear(e.target.value)}>
              <option value="">Select Year</option>
              {[2024,2025,2026,2027,2028,2029,2030].map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            <select
              value={selectedAction}
              onChange={(e) => setSelectedAction(e.target.value)}
            >
              <option value="">Filter by Action</option>
              {Array.from(new Set(WorkingHistory.map(item => item.action))).map((action, index) => (
                <option key={index} value={action}>{action}</option>
              ))}
            </select>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Filter by Category</option>
              {Array.from(new Set(WorkingHistory.map(item => item.category))).map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </div>

          {selectedItems.length > 0 && (
            <button className="Selected-delete-button" onClick={handleDelete}>
              Delete Selected
            </button>
          )}

          <table className="dealerdtls-requests__table">
            <thead className="dealerdtls-requests__thead">
              <tr className="dealerdtls-requests__thead-row">
              <th className="dealerdtls-requests__thead-cell">
                  <input
                    type="checkbox"
                    onChange={handleCheckAll}
                    checked={selectedItems.length === currentClient.length && currentClient.length > 0}
                  />
                </th>
                <th className="dealerdtls-requests__thead-cell">Sl.No</th>
                <th className="dealerdtls-requests__thead-cell">Staff Role</th>
                <th className="dealerdtls-requests__thead-cell">Action</th>
                <th className="dealerdtls-requests__thead-cell">Category</th>
                <th className="dealerdtls-requests__thead-cell">Date</th>
                <th className="dealerdtls-requests__thead-cell">Time</th>
                <th className="dealerdtls-requests__thead-cell">View Detail</th>
              </tr>
            </thead>
            <tbody className="dealerdtls-requests__tbody">
              {currentClient.map((history, index) => {
                const datetime = new Date(history.datetime);
                const formattedDate = datetime.toLocaleDateString('en-GB');
                const formattedTime = datetime.toLocaleTimeString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                });

                return (
                  <tr key={history.id} className="dealerdtls-requests__tbody-row">
                    <td className="dealerdtls-requests__tbody-cell">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(history.id)}
                        onChange={() => handleCheckboxChange(history.id)}
                      />
                    </td>
                    <td className="dealerdtls-requests__tbody-cell">{indexOfFirstClient + index + 1}</td>
                    <td className="dealerdtls-requests__tbody-cell">{history.staff_role}</td>
                    <td className="dealerdtls-requests__tbody-cell">{history.action}</td>
                    <td className="dealerdtls-requests__tbody-cell">{history.category}</td>
                    <td className="dealerdtls-requests__tbody-cell">{formattedDate}</td>
                    <td className="dealerdtls-requests__tbody-cell">{formattedTime}</td>
                    <td className="dealerdtls-requests__tbody-cell"><i className="ri-eye-fill icon-green" style={{color:'green',cursor:'pointer'}} onClick={() => handleIconClick(history)}></i></td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination */}
          {HistoryFilter.length > 10 && (
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination__button"
              >
                &laquo; Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`pagination__button ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination__button"
              >
                Next &raquo;
              </button>
            </div>
          )}

          {loading ? (
            <center><div className="waitingspinner2"></div></center>
          ) : (HistoryFilter.length < 1 && (
            <center className='dlrno--data'><p>No action found...!</p></center>
          ))}
        </div>
      </div>

      {isPopupVisible && (
  <div className="hiscustom-popup">
    <div className="hiscustom-popup-content">
      <h3>Details</h3>
      <p><strong>Role:</strong> {popupData.staff_role}</p>
      <p><strong>Action:</strong> {popupData.action}</p>
      <p><strong>Category:</strong> {popupData.category}</p>
      <p><strong>Date:</strong> {new Date(popupData.datetime).toLocaleDateString("en-GB")}</p>
      <p><strong>Time:</strong> {new Date(popupData.datetime).toLocaleTimeString("en-GB")}</p>
      
      {popupData.prev_data && (
        <div>
          <p><strong>Previous Data:</strong></p>
          <table className="hiscustom-data-table">
            <thead>
              <tr>
                {Object.keys(JSON.parse(popupData.prev_data)[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {JSON.parse(popupData.prev_data).map((item, index) => (
                <tr key={index}>
                  {Object.values(item).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      {popupData.new_data && (
        <div>
          <p><strong>New Data:</strong></p>
          <table className="hiscustom-data-table">
            <thead>
              <tr>
                {Object.keys(JSON.parse(popupData.new_data)[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {JSON.parse(popupData.new_data).map((item, index) => (
                <tr key={index}>
                  {Object.values(item).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      {popupData.delete_data && (
        <div>
          <p><strong>Deleted Data:</strong></p>
          <table className="hiscustom-data-table">
            <thead>
              <tr>
                {Object.keys(JSON.parse(popupData.delete_data)[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {JSON.parse(popupData.delete_data).map((item, index) => (
                <tr key={index}>
                  {Object.values(item).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <button className="hiscustom-popup-close" onClick={closePopup}>Close</button>
    </div>
  </div>
)}



    </div>
  );
};

export default HistoryDetails;
